import { useQuery } from 'react-apollo';

import { InlineHighlightsQuery } from './graphql/InlineHighlightsQuery.graphql';
import type {
	InlineHighlightsQuery as InlineHighlightsQueryType,
	InlineHighlightsQueryVariables,
	InlineHighlightsQuery_comments_nodes_location_InlineComment as InlineCommentsCommentLocation,
} from './graphql/__types__/InlineHighlightsQuery';

type useInlineHighlightsProps = {
	pageId: string;
	shouldFireQuery?: boolean;
};

type markerRefMapType = { [key: string]: string };

/* Not Importing these FF's since it causes circular dependency */

export const useInlineHighlights = ({
	pageId,
	shouldFireQuery = true,
}: useInlineHighlightsProps) => {
	const { data, loading } = useQuery<InlineHighlightsQueryType, InlineHighlightsQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		InlineHighlightsQuery,
		{
			variables: { pageId },
			skip: !Boolean(shouldFireQuery),
		},
	);

	let markerRefs: string[] = [];
	let markerRefMap: markerRefMapType = {};

	const comments = data?.comments?.nodes;

	if (comments && Array.isArray(comments)) {
		// We need to create a map that connects markerRefs to commentId, we need commentId to fire analytic event
		markerRefMap = comments.reduce<markerRefMapType>((inlineRefAccumulator, comment) => {
			if (!comment) {
				return inlineRefAccumulator;
			}

			const { inlineMarkerRef } = comment.location as InlineCommentsCommentLocation;

			// We should always get top-level inline comments, but if this doesn't exist, just return
			if (!inlineMarkerRef) {
				return inlineRefAccumulator;
			}

			inlineRefAccumulator[comment?.id] = inlineMarkerRef;

			comment?.replies?.forEach((reply) => {
				if (reply) {
					const { id: replyId } = reply;
					inlineRefAccumulator[replyId] = inlineMarkerRef;
				}
			});

			return inlineRefAccumulator;
		}, {});

		markerRefs = [...new Set(Object.values(markerRefMap))];
	}

	return {
		markerRefs,
		markerRefMap,
		loading,
	};
};

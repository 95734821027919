import type { FC } from 'react';
import React, { useEffect, useCallback, useContext, Fragment, useRef } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useUnreadComments } from '@confluence/unread-comments';
import {
	useCommentsContentState,
	useCommentsContentActions,
	useInlineCommentsDispatchContext,
} from '@confluence/comment-context';
import { useInlineCommentQueryParams } from '@confluence/comment';
import { PageSegmentLoadStart, PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { DialogsStateContainer } from '@confluence/dialogs';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { getLogger } from '@confluence/logger';
import { getMark } from '@confluence/performance';
import { useInlineHighlights } from '@confluence/inline-highlights-query';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import {
	HIGHLIGHT_CLASS,
	ACTIVE_CLASS,
	getInlineMarkSelector,
	clearActiveHighlight,
	setCommentAsActive,
} from '@confluence/comments-util/entry-points/domUtils';
import { getMarkerRef } from '@confluence/comments-util';
import { INLINE_COMMENTS_HIGHLIGHTS_METRIC } from '@confluence/inline-comments-common/entry-points/pageSegmentLoadMetrics';
import { useCommentsDataActions } from '@confluence/comments-data';
import { fg } from '@confluence/feature-gating';

import { RENDERER_INLINE_COMMENT_RENDER_METRIC } from '../perf.config';

type InlineCommentsHighlighterComponentProps = {
	pageId: string;
	dialogs: DialogsStateContainer;
};

type InlineCommentsHighlighterProps = {
	pageId: string;
	isFabricPage: boolean;
};

const logger = getLogger('inline-comment');

const isMarkerRef = (element) =>
	// Fabric pages use the attribute data-mark-annotation-type="inlineComment"
	// while TinyMCE pages use the class "inline-comment-marker" for document marks
	element && getMarkerRef(element) !== null;

export const FIRST_MARK_HIGHLIGHT = 'first-mark-highlight';
export const LAST_MARK_HIGHLIGHT = 'last-mark-highlight';

export const InlineCommentsHighlighterComponent: FC<InlineCommentsHighlighterComponentProps> = ({
	pageId,
	dialogs,
}) => {
	const { match } = useContext(RoutesContext);
	const { removeCommentQueryParams } = useInlineCommentQueryParams();

	const { markerRefs, markerRefMap, loading } = useInlineHighlights({
		pageId,
	});

	const { setOrderedActiveAnnotationIdList } = useCommentsDataActions();

	const { hasContentChanged } = useCommentsContentState();
	const { resetContentChanged } = useCommentsContentActions();

	const [{ unreadCommentsListState }] = useUnreadComments();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { onHighlightClick } = useInlineCommentsDispatchContext();
	const ssrHighlight = useRef<string | null>(null);

	const highlightsLoadedRef = useRef<boolean>(false);

	const focusedHighlightRef = useRef<boolean | null>(null);
	const focusedCommentId =
		typeof match?.query.focusedCommentId === 'string' ? match?.query.focusedCommentId : undefined;

	const isValidInlineComment = useCallback(
		(element: any, isSSR = false) => {
			let isValid = false;
			// If the clicked element is a resolved comment mark, we need to search
			// up its parent elements for a valid mark that has not been resolved
			while (element && isMarkerRef(element)) {
				if (isSSR) {
					const markerRef = getMarkerRef(element);
					isValid = Boolean(markerRef && markerRefs.includes(markerRef));
				} else {
					isValid = element.classList.contains(HIGHLIGHT_CLASS);
				}
				if (isValid) {
					break;
				}

				element = element.parentElement;
			}

			return { isValid, element };
		},
		[markerRefs],
	);

	// Analytic event for inline comment highlight click
	const fireUIEvent = useCallback(
		(markerRef: any, isSSR: boolean) => {
			const commentIds = Object.keys(markerRefMap);
			const commentId = commentIds.find((id) => markerRefMap?.[id] === markerRef);
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'inlineCommentHighlight',
					attributes: {
						mode: 'view',
						context: 'default',
						isSSR,
						unreadCommentCount: unreadCommentsListState?.length,
					},
					objectType: 'inlineComment',
					objectId: commentId && markerRefMap[commentId],
					source: 'viewPageScreen',
				},
			}).fire();
		},
		[createAnalyticsEvent, markerRefMap, unreadCommentsListState],
	);

	const doHighlightClick = useCallback(
		({ element, markerRef, isSSR = false }: any = {}) => {
			resetContentChanged();
			// If the clicked highlight is active, unselect it
			if (!isSSR && element.classList.contains(ACTIVE_CLASS)) {
				clearActiveHighlight();
				onHighlightClick(null);
			} else {
				clearActiveHighlight();
				element.classList.add(ACTIVE_CLASS);
				setCommentAsActive(markerRef);
				onHighlightClick(markerRef);
				fireUIEvent(markerRef, isSSR);
			}
		},
		[onHighlightClick, resetContentChanged, fireUIEvent],
	);

	const handleSSRHighlightClick = useCallback(
		(target: any) => {
			const { isValid, element } = isValidInlineComment(target, true);
			if (!element || !isValid) {
				return;
			}
			/*SSR Render start*/
			RENDERER_INLINE_COMMENT_RENDER_METRIC.start();
			const markerRef = getMarkerRef(element);
			ssrHighlight.current = markerRef;
			doHighlightClick({ element, markerRef, isSSR: true });
		},
		[doHighlightClick, isValidInlineComment],
	);

	const handleHighlightClick = useCallback(
		(e: any) => {
			const { target } = e;
			// If we can't find a valid highlight at the click location, just return
			const { isValid, element } = isValidInlineComment(target);

			if (!element || !isValid) {
				return;
			}

			e.preventDefault();
			/*SPA Render start*/
			RENDERER_INLINE_COMMENT_RENDER_METRIC.start();
			removeCommentQueryParams(); //clear any query params if user clicks a highlight

			const markerRef = getMarkerRef(element);

			if (hasContentChanged) {
				dialogs.showModal(CommentWarningDialog, {
					onConfirm: () => doHighlightClick({ element, markerRef }),
				});
			} else {
				doHighlightClick({ element, markerRef });
			}
		},
		[isValidInlineComment, removeCommentQueryParams, hasContentChanged, dialogs, doHighlightClick],
	);

	useEffect(() => {
		try {
			if (
				!ssrHighlight.current &&
				markerRefs.length > 0 &&
				window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ &&
				window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['clickedInlineHighlightElements'] &&
				Array.isArray(
					window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['clickedInlineHighlightElements'],
				)
			) {
				/* get last clicked element */
				const clickedHighlights =
					window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['clickedInlineHighlightElements'];
				const lastClickedElement = clickedHighlights[clickedHighlights.length - 1];

				if (lastClickedElement) {
					handleSSRHighlightClick(lastClickedElement);
				}
			}
		} catch (err) {
			logger.log`An error occurred when opening SSR Inline higlight - ${err}`;
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [markerRefs]);

	const handleExpandMacroClick = useCallback(
		(expandMacroButtonElClick: Event) => {
			if (!expandMacroButtonElClick?.target) {
				return;
			}

			const targetExpandMacroElement = expandMacroButtonElClick.target as HTMLElement;

			// get the expand macro container
			const expandMacroEl =
				targetExpandMacroElement.closest(`[data-node-type="expand"]`) ||
				targetExpandMacroElement.closest(`[data-node-type="nestedExpand"]`);

			// look for the active highlight so we can close the inline comment
			const expandMacroContentEl =
				expandMacroEl?.querySelector('.expand-content-wrapper') ||
				expandMacroEl?.querySelector('.nestedExpand-content-wrapper');
			const activeHighlight = expandMacroContentEl?.querySelector('.active-highlight');
			if (expandMacroEl?.getAttribute('data-expanded') === 'true' && activeHighlight) {
				clearActiveHighlight();
				onHighlightClick(null);
			}
		},
		[onHighlightClick],
	);

	useEffect(() => {
		const contentEl = document.querySelector('div#content[data-inline-comments-target="true"]');
		if (!contentEl) {
			return;
		}
		contentEl.addEventListener('click', handleHighlightClick);

		// handle expand macro click to close inline comment
		const expandMacroButtonElements: NodeListOf<HTMLButtonElement> = document.querySelectorAll(
			'button[aria-labelledby^="expand-title"]',
		);
		if (expandMacroButtonElements) {
			expandMacroButtonElements.forEach((button) => {
				button.addEventListener('click', handleExpandMacroClick);
			});
		}

		return () => {
			// Remove the event listeners
			contentEl.removeEventListener('click', handleHighlightClick);

			if (expandMacroButtonElements) {
				expandMacroButtonElements.forEach((button) => {
					button.removeEventListener('click', handleExpandMacroClick);
				});
			}
		};
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [handleHighlightClick, pageId]);

	useEffect(() => {
		/* Add Highlights when SPA loads*/
		const marks = document.querySelectorAll(getInlineMarkSelector());
		const markerRefDict: { [key: string]: { start: Element; end: Element } } = {};
		const markerRefsSet = new Set(
			Array.from(marks).map((mark) => getMarkerRef(mark as HTMLElement)),
		);
		markerRefsSet.forEach((ref) => {
			const marksForRef = Array.from(marks).filter(
				(mark) => getMarkerRef(mark as HTMLElement) === ref,
			);
			if (ref) {
				markerRefDict[ref] = {
					start: marksForRef[0],
					end: marksForRef[marksForRef.length - 1],
				};
			}
		});
		// Loop through the marks and add or remove styles based on their presence in the map
		marks.forEach((mark) => {
			const markerRef = getMarkerRef(mark as HTMLElement);
			if (markerRef) {
				const shouldHighlight = markerRefs.includes(markerRef);
				// Add the highlight class only if the mark is in the map
				if (shouldHighlight) {
					mark.classList.add(HIGHLIGHT_CLASS);
					//Mark first and last classes
					if (mark === markerRefDict[markerRef].start) {
						mark.classList.add(FIRST_MARK_HIGHLIGHT);
					}
					if (mark === markerRefDict[markerRef].end) {
						mark.classList.add(LAST_MARK_HIGHLIGHT);
					}
				} else {
					// Otherwise, remove it
					mark.classList.remove(HIGHLIGHT_CLASS);
					mark.classList.remove(FIRST_MARK_HIGHLIGHT);
					mark.classList.remove(LAST_MARK_HIGHLIGHT);
				}
				/* Re-add active class for SSR highlights since component re-render will clear active state*/
				if (ssrHighlight.current === markerRef) {
					mark.classList.add(ACTIVE_CLASS);
					ssrHighlight.current = null;
				} else if (
					!focusedHighlightRef.current &&
					focusedCommentId &&
					markerRefMap[focusedCommentId] === markerRef
				) {
					mark.classList.add(ACTIVE_CLASS);
					focusedHighlightRef.current = true;
				}
			}
		});

		if (fg('confluence-frontend-comments-panel')) {
			const activeRefList = Array.from(document.querySelectorAll('.inline-highlight')).map(
				(highlight) => highlight.getAttribute('data-ref'),
			) as string[];

			if (!highlightsLoadedRef.current && activeRefList.length > 0) {
				highlightsLoadedRef.current = true;

				setOrderedActiveAnnotationIdList(activeRefList);
			}
		}

		/* Mark FMP complete for inline highlights Render - SSR*/
		INLINE_COMMENTS_HIGHLIGHTS_METRIC.markFMP(getMark('CFP-63.ssr-ttr'));

		/* Mark FMP complete for inline highlights Render - SPA*/
		INLINE_COMMENTS_HIGHLIGHTS_METRIC.mark('highlightsRendered');
	}, [markerRefs, pageId, focusedCommentId, markerRefMap, setOrderedActiveAnnotationIdList]);

	// Save unresolved marks and focusedMarkerRef for use in SSRInlineScriptsInit.tsx
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		window.__SSR_INLINE_COMMENT_MARKS__ = markerRefs;
		// Save focusedMarkerRef
		if (focusedCommentId) {
			window.__SSR_FOCUSED_INLINE_COMMENTS__ = markerRefMap[focusedCommentId];
		}
	}

	const numberOfInlineComments = markerRefs && Array.isArray(markerRefs) ? markerRefs.length : 0;

	if (!loading) {
		return (
			<Fragment>
				<PageSegmentLoadEnd
					key={`stop-${pageId}`}
					metric={INLINE_COMMENTS_HIGHLIGHTS_METRIC}
					customData={{ numberOfInlineComments, isFabricPage: false }}
				/>
			</Fragment>
		);
	}

	return null;
};

export const InlineCommentsHighlighter: FC<InlineCommentsHighlighterProps> = ({
	pageId,
	isFabricPage,
}) => {
	if (isFabricPage) {
		return null;
	} else {
		return (
			<TransparentErrorBoundary attribution={Attribution.COLLABORATION}>
				<Subscribe to={[DialogsStateContainer]}>
					{(dialogs: DialogsStateContainer) => (
						<Fragment>
							<PageSegmentLoadStart
								key={`start-${pageId}`}
								metric={INLINE_COMMENTS_HIGHLIGHTS_METRIC}
							/>
							<InlineCommentsHighlighterComponent pageId={pageId} dialogs={dialogs} />
						</Fragment>
					)}
				</Subscribe>
			</TransparentErrorBoundary>
		);
	}
};

import gql from 'graphql-tag';

export const InlineHighlightsQuery = gql`
	query InlineHighlightsQuery($pageId: ID!) {
		comments(pageId: $pageId, type: UNRESOLVED, depth: ROOT, first: 1000) {
			nodes {
				id
				location {
					type
					... on InlineComment {
						inlineMarkerRef
					}
				}
				replies {
					id
				}
			}
		}
	}
`;

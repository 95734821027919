import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook, createActionsHook, createStateHook } from 'react-sweet-state';

export type CommentsContentState = {
	hasContentChanged: boolean;
	isCommentContentReady: boolean;
};

const initialState: CommentsContentState = {
	hasContentChanged: false,
	isCommentContentReady: false,
};

export const getInitialState = (): CommentsContentState => ({
	hasContentChanged: false,
	isCommentContentReady: false,
});

export const actions = {
	onChange:
		(hasChanges: boolean) =>
		({ setState }: StoreActionApi<CommentsContentState>) => {
			setState({ hasContentChanged: hasChanges });
		},
	resetContentChanged:
		() =>
		({ setState }: StoreActionApi<CommentsContentState>) => {
			setState({ hasContentChanged: false });
		},
	onCommentContentReady:
		() =>
		({ setState }: StoreActionApi<CommentsContentState>) => {
			setState({ isCommentContentReady: true });
		},
};

const CommentsContentStore = createStore({
	initialState,
	actions,
	name: 'CommentsContentStore',
});

export const useCommentsContent = createHook(CommentsContentStore);
export const useCommentsContentActions = createActionsHook(CommentsContentStore);
export const useCommentsContentState = createStateHook(CommentsContentStore);
